.dinner__side-beans {
    background-image: url(../../../../assets/lunch/beans.JPG);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__side-veg {
    background-image: url(../../../../assets/dinner/veg.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__side-beans {
    background-image: url(../../../../assets/lunch/beans.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dinner__side-green-beans {
    background-image: url(../../../../assets/dinner/dinner-box-four.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__side-mac {
    background-image: url(../../../../assets/lunch/lunch-banner-three.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}