.recep__dessert-straw {
    background-image: url(../../../../assets/recep/straw.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__dessert-almond {
    background-image: url(../../../../assets/recep/almond.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__dessert-oreo {
    background-image: url(../../../../assets/recep/oreo.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__dessert-coconut{
    background-image: url(../../../../assets/recep/coconut.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__dessert-tart {
    background-image: url(../../../../assets/recep/recep-box-three.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__dessert-eclair {
    background-image: url(../../../../assets/recep/eclair.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__dessert-crepe {
    background-image: url(../../../../assets/recep/crepe.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}