.home__call-space {
    margin: $padding-between 0;
}

//retangle
.home__call-retangle{
    background-color: $thm-light-purple;
    width: 100%;
    height: 200px;
    z-index: -1;
    float: left;
    margin-top: 150px;
}

//positioning
.home__call-container{
    position: relative;
    height: 500px;
}

.home__call-text-block{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255,255,255,.85) 70%);
    padding: 0 75px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home__call-text-block h2 {
    font-size: $thm-font-xxult;
    max-width: 350px;
    margin-bottom: 0;
}

//images
.home__call-img{
    background-image: url(../../../../assets/home/call.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
}

@media only screen and (max-width: 1350px) {
    .home__call-img, .home__call-container{
        height: 400px;
    }
    .home__call-retangle{
        margin-top: 100px;
    }
}
@media only screen and (max-width: 1350px) {
    .home__call-text-block h2 {
        font-size: $thm-font-xult;
    }
    .home__call-text-block{
        padding: 0 50px;
    }
}