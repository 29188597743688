.recep__brownies-reese {
    background-image: url(../../../../assets/lunch/reese.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__brownies-mint {
    background-image: url(../../../../assets/lunch/mint.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__brownies-rocky {
    background-image: url(../../../../assets/lunch/rocky.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}