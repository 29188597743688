.recep__savory-smokies {
    background-image: url(../../../../assets/recep/smokies.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-bac {
    background-image: url(../../../../assets/recep/bac.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-spinach {
    background-image: url(../../../../assets/services.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-pizza{
    background-image: url(../../../../assets/recep/pizza.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-kabobs {
    background-image: url(../../../../assets/recep/recep-box-four.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-puff{
    background-image: url(../../../../assets/recep/puff.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__savory-mac {
    background-image: url(../../../../assets/lunch/lunch-banner-three.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}