.drinks__beverages-punch {
    background-image: url(../../../../assets/drinks/drinks-punch.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.drinks__beverages-italian-soda {
    background-image: url(../../../../assets/drinks/italian-ice.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.drinks__beverages-coco {
    background-image: url(../../../../assets/drinks/drinks-coco.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}