.recep__cookie-thumb {
    background-image: url(../../../../assets/lunch/tumb.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__cookie-mini {
    background-image: url(../../../../assets/recep/mini.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__cookie-macaroon {
    background-image: url(../../../../assets/recep/macaroon.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}