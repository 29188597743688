.lunch__salad-spinach {
    background-image: url(../../../assets/lunch/spinach.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.lunch__salad-pasta {
    background-image: url(../../../assets/lunch/lunch-banner-two.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}