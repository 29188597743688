.recep__fruit-veggie {
    background-image: url(../../../../assets/lunch/veggie.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__fruit-fruit {
    background-image: url(../../../../assets/lunch/fruit.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__fruit-cup {
    background-image: url(../../../../assets/recep/cup.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.recep__fruit-kabob {
    background-image: url(../../../../assets/home/recep.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}