.dinner__dessert-cheese-cake {
    background-image: url(../../../../assets/dinner/cheese-cake.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__dessert-choc-cake {
    background-image: url(../../../../assets/dinner/choc-cake.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover; 
}

.dinner__dessert-eclair {
    background-image: url(../../../../assets/dinner/eclair.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.dinner__dessert-coc-cake {
    background-image: url(../../../../assets/dinner/coc-cake.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__dessert-ny-cake {
    background-image: url(../../../../assets/dinner/ny-cake.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dinner__dessert-lemon {
    background-image: url(../../../../assets/dinner/lemon.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}