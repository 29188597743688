.recep__cake-bundt {
    background-image: url(../../../../assets/recep/bundt.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__cake-cupcake {
    background-image: url(../../../../assets/recep/cupcake.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.recep__cake-cheese {
    background-image: url(../../../../assets/recep/cheese.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}