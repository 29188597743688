.drinks__banner-container {
    position: relative;
    text-align: center;
    color: white;
}

.drinks__banner-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgba(144, 61, 92, 0.9);
    padding: 40px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.drinks__banner-background h1{
    margin: auto 0;
}

//grid
.drinks__banner-grid {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 2fr 2fr;
    height: 550px;
    grid-gap: 5px;
    z-index: -1;
    margin-bottom: $padding-between;
}

.drinks__banner-box-one {
    background-image: url(../../../../assets/drinks/drinks-coco.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}

.drinks__banner-box-two {
    background-image: url(../../../../assets/drinks/drinks-punch.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}